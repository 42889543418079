:root {
  font-size: 62.5%;
}


body {
  margin: 0;
  font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  font-size: 1.6rem;
  /* overscroll-behavior-y: none; */
}

/* html {
  overscroll-behavior-y: none;
} */


::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./fonts/Montserrat-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'JetBrains';
  font-weight: 300;
  src: local('JetBrains'), url(./fonts/JetBrainsMono-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'JetBrains';
  src: local('JetBrains'), url(./fonts/JetBrainsMono-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'JetBrains';
  font-weight: 500;
  src: local('JetBrains'), url(./fonts/JetBrainsMono-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'JetBrains';
  font-weight: 600;
  src: local('JetBrains'), url(./fonts/JetBrainsMono-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'JetBrains';
  font-weight: 700;
  src: local('JetBrains'), url(./fonts/JetBrainsMono-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Arame';
  font-weight: 300;
  src: local('Arame'), url(./fonts/Arame\ Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Arame';
  src: local('Arame'), url(./fonts/ArameMono.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Regular.otf) format('opentype');
}

@font-face {
  font-weight: 300;
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Light.otf) format('opentype');
}

@font-face {
  font-weight: 500;
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Medium.otf) format('opentype');
}

@font-face {
  font-weight: 600;
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Devant Horgen';
  src: local('Devant Horgen'), url(./fonts/Devant-Horgen.ttf) format('truetype');
}

@font-face {
  font-family: 'RetroGaming';
  src: local('RetroGaming'), url(./fonts/RetroGaming.ttf) format('truetype');
}


/* setting global font */

* {
  font-family: 'NeueMontreal', 'JetBrains', 'Arame', 'Montserrat', Arial, Helvetica, sans-serif;
}
